import React, { useState } from "react";
import styled from "styled-components";
import TokensMap from "../components/TokensMap";
import UserTokens from "../components/UserTokens";
import * as s from "../styles/globalStyles";

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media ${s.devices.tablet} {
    flex-direction: row;
  }
`;

function Console() {
  const [selectedToken, setSelectedToken] = useState(0);

  return (
    <s.Container
      flex={1}
      ai={"center"}
      style={{ backgroundColor: "var(--primary)" }}
    >
      <s.SpacerSmall />
      <ResponsiveWrapper flex={1} style={{ padding: 48 }}>
        <s.SpacerLarge />
        <s.Container
          flex={1}
          jc={"center"}
          ai={"center"}
          withBorder={true}
          style={{ padding: 24 }}
        >
          <s.TextTitle
            secondary={true}
            centered={true}
          >
            ELDER ENTS
          </s.TextTitle>
          <UserTokens
            selectedToken={selectedToken}
            setSelectedToken={(idx) => setSelectedToken(idx)}
          />
        </s.Container>
        <s.SpacerLarge />
        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          fd={"column"}
          withBorder={true}
        >
          <TokensMap
            selectedToken={selectedToken}
            setSelectedToken={(idx) => setSelectedToken(idx)}
          />
        </s.Container>
      </ResponsiveWrapper>
    </s.Container>
  );
}

export default Console;