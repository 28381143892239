import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { connect, disconnect } from "../../redux/blockchain/blockchainActions";
import * as s from "../../styles/globalStyles";
import { truncate } from "../../utils/utils";

export const HeaderLogo = styled.img`
  height: 80px;
  margin: 0 auto;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HeaderButton = styled(s.PrimaryButton)`
  &:hover {
    color: var(--primary-text);
  }
`;

export const DropdownLink = styled.a`
  color: var(--secondary-text);
  padding: 5px;

  &:hover {
    cursor: pointer;
    color: var(--primary-text);
    background-color: rgba(0,0,0,0.1);
  }
`;

export const CenteredTextWrapper = styled.div`
  text-align: center;
`;

function Header() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  return (
    <s.Container
      fd={"row"}
      jc={"flex-end"}
      ai={"center"}
      style={{
        position: "relative",
        paddingTop: 24,
        paddingRight: 48,
        paddingLeft: 48,
        zIndex: 1000
      }}
    >
      <s.Container flex={"1 0 33.333%"} />
      <CenteredTextWrapper><HeaderLogo alt={"logo"} src={"/config/images/logo.png"} /></CenteredTextWrapper>
      <s.Container flex={"1 0 33.333%"} fd={"row"} jc={"flex-end"}>
        <CenteredTextWrapper>
          {
            (
              !blockchain.account ||
              !blockchain.smartContract
            ) ?
              <s.PrimaryButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                }}
              >
                Connect Wallet
              </s.PrimaryButton>
              :
              <ButtonsContainer>
                <HeaderButton>
                  {truncate(blockchain.account, 8)}
                </HeaderButton>
                <HeaderButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(disconnect());
                  }}
                >
                  Disconnect
                </HeaderButton>
              </ButtonsContainer>
          }
        </CenteredTextWrapper>
      </s.Container>
    </s.Container>
  );
}

export default Header;
