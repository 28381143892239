import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import { getTokensAlchemy, reset } from "../alchemy/alchemyActions";
import { ERROR_MESSAGES } from "../../constants/errors";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload,
  };
};

const disconnectWallet = () => {
  return {
    type: "DISCONNECT",
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

const clearErrorsRequest = () => {
  return {
    type: "CLEAR_ERRORS",
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const ENV = process.env;

    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (!metamaskIsInstalled) {
      dispatch(connectFailed(ERROR_MESSAGES.METAMASK_NOT_INSTALLED));
      return;
    }

    Web3EthContract.setProvider(ethereum);
    let web3 = new Web3(ethereum);
    try {
      const hexChainId = await ethereum.request({ method: 'eth_chainId' });
      const chainId = String(parseInt(hexChainId, 16));
      if (chainId !== ENV.REACT_APP_CHAIN_ID) {
        dispatch(connectFailed(ERROR_MESSAGES.WRONG_CHAIN.replace('$i', ENV.REACT_APP_CHAIN_NAME)));
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      const SmartContractObj = new Web3EthContract(
        abi,
        ENV.REACT_APP_CONTRACT_ADDRESS
      );
      dispatch(
        connectSuccess({
          account: accounts[0],
          smartContract: SmartContractObj,
          web3: web3,
        })
      );
      dispatch(getTokensAlchemy());
      // Add listeners start
      ethereum.on("accountsChanged", (accounts) => {
        dispatch(updateAccount(accounts[0]));
      });
      ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    } catch (err) {
      dispatch(connectFailed(ERROR_MESSAGES.GENERIC_ERROR));
    }
  };
};

export const disconnect = () => {
  return async (dispatch) => {
    dispatch(disconnectWallet());
    dispatch(reset());
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
  };
};

export const clearErrors = () => {
  return async (dispatch) => {
    dispatch(clearErrorsRequest());
  };
};
