import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as s from "../styles/globalStyles";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getTokenOpenseaUrl } from "../utils/utils";
import TokenVideo from "./TokenVideo";
import TokenImage from "./TokenImage";

const VideoContainer = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  height: 420px;
`;

const VideoIcnContainer = styled.div`
  position: absolute;
  bottom: 12px; 
  right: 12px;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
`;

const TokenImgContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 33.3333%;
  padding: 24px;
  cursor: pointer;
`;

function UserTokens(props) {
  const { selectedToken, setSelectedToken } = props;
  const alchemy = useSelector((state) => state.alchemy);
  const [tokens, setTokens] = useState([]);
  const [tokenIdVideoToPlay, setTokenIdVideoToPlay] = useState(null);
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);

  const onVideoLoad = () => {
    setVideoIsLoaded(true);
  }

  const selectVideo = (id) => {
    setVideoIsLoaded(false);
    setTokenIdVideoToPlay(id);
  }

  const goToOpensea = (url) => {
    window.open(url, '_blank');
  }

  const previousToken = () => {
    if (selectedToken === 0) setSelectedToken(tokens.length - 1);
    else if (selectedToken > 0) setSelectedToken(selectedToken - 1);
  }

  const nextToken = () => {
    if (selectedToken === tokens.length - 1) setSelectedToken(0);
    if (selectedToken < tokens.length - 1) setSelectedToken(selectedToken + 1);
  }

  useEffect(() => {
    const userTokens = alchemy.tokens.map((token, i) => generateToken(token, i));
    setTokens(userTokens);
  }, [alchemy.tokens, tokenIdVideoToPlay, videoIsLoaded]);

  const generateToken = (token, i) => {
    const { rawMetadata, tokenId } = token;
    const {
      animation_url: tokenVideoUrl,
      name: entCode,
      image: tokenImgUrl,
    } = rawMetadata;
    const openseaUrl = getTokenOpenseaUrl(tokenId);

    return (
      <TokenContainer key={tokenId}>
        <TokenImgContainer onClick={() => goToOpensea(openseaUrl)}>
          {
            tokenIdVideoToPlay === i &&
            <VideoContainer hide={!videoIsLoaded}>
              <TokenVideo src={tokenVideoUrl} onLoad={onVideoLoad} />
            </VideoContainer>
          }
          <TokenImage
            entCode={entCode}
            src={tokenImgUrl}
            hide={tokenIdVideoToPlay === i && videoIsLoaded}
          />
          <VideoIcnContainer>
            {
              tokenIdVideoToPlay === i
                ? <StopCircleOutlinedIcon onClick={(e) => { e.stopPropagation(); selectVideo(null); }} />
                : <PlayCircleOutlinedIcon onClick={(e) => { e.stopPropagation(); selectVideo(i); }} />
            }
          </VideoIcnContainer>
        </TokenImgContainer>
        <s.TextSubTitle
          secondary={true}
          style={{
            width: "100%",
            paddingTop: 5,
            paddingLeft: 10
          }}
        >
          { entCode }
        </s.TextSubTitle>
      </TokenContainer>
    );
  };

  return (
    <s.Container
      flex={1}
      jc={"center"}
      ai={"center"}
      fd={"row"}
      style={{ minHeight: 500, minWidth: 350 }}
    >
      {
        tokens.length ?
          <>
            { tokens.length > 1 && <ArrowBackIosIcon onClick={previousToken} style={{ cursor: 'pointer' }} /> }
            { tokens[selectedToken] }
            { tokens.length > 1 && <ArrowForwardIosIcon onClick={nextToken} style={{ cursor: 'pointer' }} /> }
          </> :
          <s.TextTitle centered={true}>
            No ENTS Found
          </s.TextTitle>
      }
    </s.Container>
  )
}

export default UserTokens;