const initialState = {
    alchemyInstance: null,
    user: null,
    tokens: [],
    userTokenIds: [],
    smartContract: null,
    loading: false,
    minting: false,
    error: false,
    errorMsg: "",
};

const moralisReducer = (state = initialState, action) => {
    switch (action.type) {
      case "INIT_ALCHEMY":
        return {
          ...state,
          alchemyInstance: action.payload.alchemyInstance,
        };
      case "GET_TOKENS_REQUEST":
        return {
          ...state,
          loading: true,
          error: false,
          errorMsg: "",
        };
      case "GET_TOKENS_SUCCESS":
        return {
          ...state,
          loading: false,
          tokens: action.payload.tokens,
          userTokenIds: action.payload.userTokenIds,
          error: false,
          errorMsg: "",
        };
      case "GET_TOKENS_FAILED":
        return {
          ...initialState,
          alchemyInstance: state.alchemyInstance,
          loading: false,
          error: true,
          errorMsg: action.payload,
        };
      case "RESET":
        return {
          ...initialState,
          alchemyInstance: state.alchemyInstance
        }
      default:
        return state;
    }
  };
  
  export default moralisReducer;