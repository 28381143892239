import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import styled from "styled-components";

import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { GREEN_MARKER, RED_MARKER } from '../constants/constants';
import { getENTCode } from '../utils/utils';
import { entLocations } from '../constants/entsLocations';

const MapContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
`;

const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '60vh',
};

const defaultCenter = {
    lat: -36,
    lng: -72.7
};

const options = {
    fullscreenControl: false,
    streetViewControl: false,
    gestureHandling:   'cooperative',
    mapTypeId: 'hybrid'
};

function TokensMap(props) {
    const { selectedToken, setSelectedToken } = props;
    const alchemy = useSelector((state) => state.alchemy);
    const [userTokens, setUserTokens] = useState([]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
    });

    const [markers, setMarkers] = useState();
    const [center, setCenter] = useState(defaultCenter);
    const [zoom, setZoom] = useState(8);

    const panToMarker = (position) => {
        setZoom(20);
        setCenter(position);
    }

    const findTokenIdx = (tokenId) => {
        return alchemy.tokens.findIndex((token) => tokenId === token.tokenId);
    };

    useEffect(() => {
        const tempEntsLocations = [];
        alchemy.tokens.forEach((token) => {
            const entCode = getENTCode(token);
            const entLocation = entLocations[entCode];
            tempEntsLocations.push({ entCode, ...entLocation, tokenId: token.tokenId });
        });
        setUserTokens(tempEntsLocations);
    }, [alchemy.tokens]);

    useEffect(() => {
        const tokenToMarker = (token, clusterer, isSelected) => {
            const { latitude, longitude, entCode, tokenId } = token;
    
            if (!latitude || !longitude) return null;
    
            const position = {
                lat: Number(latitude),
                lng: Number(longitude)
            };

            const ownedByUser = alchemy.userTokenIds.includes(tokenId);
    
            const icon = {
                url: ownedByUser ? RED_MARKER : GREEN_MARKER,
                scaledSize: new window.google.maps.Size(
                    isSelected ? 52 : 35,
                    isSelected ? 65 : 43,
                )
            };
    
            return <Marker
                key={entCode}
                position={position}
                icon={icon}
                clusterer={clusterer}
                onClick={() => {
                    const idx = findTokenIdx(tokenId);
                    setSelectedToken(idx);
                    panToMarker(position);
                }}
            />
        };

        const tokensToMarkers = (tokens) => {
            const options = { imagePath: '/img/maps/m' };
            return (
                <MarkerClusterer options={options}>
                    { (clusterer) => tokens.map((token, i) => tokenToMarker(token, clusterer, selectedToken === i)) }
                </MarkerClusterer>
            );
        };
        setMarkers(tokensToMarkers(userTokens));

        if (userTokens.length) {
            const selectedTokenCode = getENTCode(alchemy.tokens[selectedToken]);
            const { latitude, longitude } = userTokens.find((token) => token.entCode === selectedTokenCode);
            const position = {
                lat: Number(latitude),
                lng: Number(longitude)
            };
            panToMarker(position);
        }

    }, [userTokens, selectedToken]);

    return isLoaded ? (
        <MapContainer>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                options={options}
            >
                { markers }
            </GoogleMap>
        </MapContainer>
    ) : <></>
}

export default React.memo(TokensMap);