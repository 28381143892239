import styled from "styled-components";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

/* <------ SPACERS ------> */

export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

export const SpacerCustom = styled.div`
  height: ${({ h }) => (h ? h : "32px")};
  width: ${({ w }) => (w ? w : "32px")};
`;

/* <------ SPACERS ------> */

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  flex-wrap: ${({ fw }) => (fw ? fw : "nowrap")};
  gap: ${({ gap }) => (gap ? gap : 0)};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  ${({ withBorder }) => withBorder && `
    background-color: var(--accent);
    border-radius: 24px;
    border: 4px solid var(--secondary);
    box-shadow: 0px 5px 11px 2px rgba(0,0,0,0.7);
  `}
`;

/* <------ TEXTS ------> */

export const MainTitle = styled.p`
  color: var(--primary-text);
  font-family: 'Oswald', sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextTitle = styled.p`
  color: ${({ secondary }) => (secondary ? "var(--secondary-text)" : "var(--primary-text)")};
  font-size: 28px;
  font-weight: 600;
  line-height: 1.6;
  ${({ centered }) => centered && `
    text-align: center;
  `}
`;

export const TextSubTitle = styled.p`
  color: ${({ secondary }) => (secondary ? "var(--secondary-text)" : "var(--primary-text)")};
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.span`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

/* <------ TEXTS ------> */

/* <------ BUTTONS ------> */

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const PrimaryButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

/* <------ BUTTONS ------> */
