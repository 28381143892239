export const entLocations = {
    GKHH1: { latitude: '-36.0369316726168', longitude: '-72.7114807090298' },
    GKHH2: { latitude: '-36.0378335385802', longitude: '-72.7104560350411' },
    GKHH3: { latitude: '-36.0385848251611', longitude: '-72.711215037879' },
    GKHH4: { latitude: '-36.0387148452116', longitude: '-72.7118226731955' },
    GKHH5: { latitude: '-36.0389111385093', longitude: '-72.7118929905736' },
    GKHH6: { latitude: '-36.0389070072855', longitude: '-72.7117800100151' },
    GKHH7: { latitude: '-36.0388857399739', longitude: '-72.7117273168166' },
    GKHH8: { latitude: '-36.0397108855102', longitude: '-72.7124428194066' },
    GKHH9: { latitude: '-36.0400165717201', longitude: '-72.7120624300583' },
    GKHH10: { latitude: '-36.0401136861104', longitude: '-72.7117615265509' },
    GKHH11: { latitude: '-36.040115635385', longitude: '-72.7116370812465' },
    GKHH12: { latitude: '-36.0401381318668', longitude: '-72.7116193404276' },
    GKHH13: { latitude: '-36.0402221754134', longitude: '-72.7115246002378' },
    GKHH14: { latitude: '-36.0401844120274', longitude: '-72.7114499832007' },
    GKHH15: { latitude: '-36.0402954470716', longitude: '-72.7114387526904' },
    GKHH16: { latitude: '-36.040409838797', longitude: '-72.7114423659135' },
    GKHH17: { latitude: '-36.0403619399162', longitude: '-72.7113561110822' },
    GKHH18: { latitude: '-36.040348091271', longitude: '-72.7112949539875' },
    GKHH19: { latitude: '-36.040524130689', longitude: '-72.7111836818869' },
    GKHH20: { latitude: '-36.0413060293391', longitude: '-72.7102813889919' },
    GKHH21: { latitude: '-36.0414184286536', longitude: '-72.7100105687444' },
    GKHH22: { latitude: '-36.040412206149', longitude: '-72.7113813833451' },
    GKHH23: { latitude: '-36.0399424627925', longitude: '-72.7122522136987' },
    GKHH24: { latitude: '-36.0400094424938', longitude: '-72.7123693247301' },
    GKHH25: { latitude: '-36.0400141045138', longitude: '-72.7124036152293' },
    GKHH26: { latitude: '-36.0394511335054', longitude: '-72.7121880001909' },
};
