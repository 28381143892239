import { ELDER_CODE_TRAIT, OPENSEA_TESTNET_URL, OPENSEA_URL } from '../constants/constants';

export const getENTCode = (token) => {
    const traits = token.rawMetadata.attributes;
    const entCodeTrait = traits.find((trait) => trait.trait_type === ELDER_CODE_TRAIT);
    return entCodeTrait?.value;
};

export const getTokenOpenseaUrl = (tokenId) => {
    const openseaUrl = Boolean(process.env.REACT_APP_IS_MAINNET) ? OPENSEA_URL : OPENSEA_TESTNET_URL;
    const contractUrl = process.env.REACT_APP_CONTRACT_ADDRESS;
    const chainName = process.env.REACT_APP_CHAIN_NAME;
    return `${openseaUrl}/assets/${chainName}/${contractUrl}/${tokenId}`;
}

export const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;
