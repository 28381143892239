import { Network, Alchemy } from "alchemy-sdk";
import store from "../store";
import { ERROR_MESSAGES } from '../../constants/errors';

const initAlchemy = (payload) => {
  return {
    type: "INIT_ALCHEMY",
    payload
  };
};

const getTokensRequest = () => {
  return {
    type: "GET_TOKENS_REQUEST",
  };
};

const getTokensSuccess = (payload) => {
  return {
    type: "GET_TOKENS_SUCCESS",
    payload
  };
};

const getTokensFailed = (payload) => {
  return {
    type: "GET_TOKENS_FAILED",
    payload
  };
};

const resetAll = () => {
  return { type: "RESET" };
};

const getUserTokensAlchemy = async (alchemy) => {
  const storeInstance = await store.getState();
  const blockchain = storeInstance.blockchain;
  const userAddress = blockchain.account;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const userEnts = await alchemy.nft.getNftsForOwner(userAddress, {
    contractAddresses: [contractAddress]
  });
  return userEnts;
};

const getUserTokensIdsAlchemy = async (alchemy) => {
  const userTokens = await getUserTokensAlchemy(alchemy);
  const ids = userTokens.ownedNfts.map(token => token.tokenId);
  return ids;
};

const getCollectionTokensAlchemy = async (alchemy) => {
  const address = process.env.REACT_APP_CONTRACT_ADDRESS;
  if (alchemy) {
    const tokens = await alchemy.nft.getNftsForContract(address);
    return tokens.nfts;
  }
  return [];
};

export const initializeAlchemy = () => {
  return async (dispatch) => {
    const ENV = process.env;
    const settings = {
      apiKey: ENV.REACT_APP_ALCHEMY_KEY,
      network: ENV.REACT_APP_IS_MAINNET ? Network.MATIC_MAINNET : Network.MATIC_MUMBAI,
    };
    const alchemyInstance = new Alchemy(settings);
    dispatch(initAlchemy({ alchemyInstance }));
  }
};

export const getTokensAlchemy = () => {
  return async (dispatch) => {
      dispatch(getTokensRequest());
      try {
        const alchemy = await store.getState().alchemy.alchemyInstance;
        console.log(alchemy);
        const userTokenIds = await getUserTokensIdsAlchemy(alchemy)
        if (!userTokenIds.length) {
          dispatch(getTokensFailed(ERROR_MESSAGES.NOT_OWNER));
          return;
        }
        const tokens = await getCollectionTokensAlchemy(alchemy);
        const userTokens = tokens.filter((token) => userTokenIds.includes(token.tokenId));
        const otherTokens = tokens.filter((token) => !userTokenIds.includes(token.tokenId));
        const sortedTokens = [...userTokens, ...otherTokens];
        console.log(sortedTokens);
        dispatch(getTokensSuccess({ tokens: sortedTokens, userTokenIds }));
      } catch (err) {
        console.log(err);
        dispatch(getTokensFailed(ERROR_MESSAGES.GENERIC_ERROR));
      }
  }
}

export const reset = () => {
  return async (dispatch) => {
      dispatch(resetAll());
  }
}
