import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router
} from "react-router-dom";
import Header from "./components/layout/Header";
import Console from "./pages/Console";
import ToastAlert from "./components/ToastAlert";
import { clearErrors } from "./redux/blockchain/blockchainActions";
import { initializeAlchemy } from "./redux/alchemy/alchemyActions";
import * as s from "./styles/globalStyles";

function App() {
  const [displayToast, setDisplayToast] = useState(false);
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const alchemy = useSelector((state) => state.alchemy);

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayToast(false);
    dispatch(clearErrors());
  };

  useEffect(() => {
    dispatch(initializeAlchemy());
  }, []);

  useEffect(() => {
    if (blockchain.errorMsg || alchemy.errorMsg) {
      setDisplayToast(true);
    } else {
      setDisplayToast(false);
    }
  }, [blockchain.errorMsg, alchemy.errorMsg]);

  return (
    <s.Screen>
      <Router>
        <s.Container
          flex={1}
          fd={"column"}
          ai={"center"}
        >
          <Header />
          <Console />
          {/* <Routes>
            <Route path="/discover" element={<Gallery />} />
            <Route index element={<Buy />} />
          </Routes> */}
        </s.Container>
        <ToastAlert open={displayToast} handleClose={handleCloseToast} message={blockchain.errorMsg || alchemy.errorMsg} />
      </Router>
    </s.Screen>
  );
}

export default App;
